  
// Base
$base-font: "Open Sans", "Helvetica Neue", Arial, sans-serif;
$title-font:'Varela';

// Font Weights
$font-weight-normal: normal;
$font-weight-400: 400;
$font-weight-600: 600;
$font-weight-800: 800;



$font-style-normal:normal;



// Header Sizes
$font-size-h1: 25px;
$font-size-h2: 18px;
$font-size-h3: 17px;
$font-size-h4:  16px;
$font-16:16px;
$font-14:14px;
$font-12:12px;

// Colors
$white: #FFFFFF;
$dark-black:#000000;
$black: rgb(37, 37, 37);
$light-black:rgb(102, 102, 102);
$red: #f00;
$blue:rgb(0, 82, 158);
$sky-blue:rgb(117, 190, 255);
$light-sky-blue:rgb(223, 240, 255);
$dark-blue:rgb(14, 101, 181);
$light-pink:rgb(255, 245, 206);
$grey:rgb(179, 179, 179);
$para-text:rgb(64, 64, 64);
$header-bg:rgb(129, 199, 231);
$mob-menu-txt:rgb(5, 5, 5);



//placeholder
@mixin placeholder {
  ::-webkit-input-placeholder {@content};
  :-moz-placeholder           {@content};
  ::-moz-placeholder          {@content};
  :-ms-input-placeholder      {@content}; 
}



// Breakpoints
$desktop-sm: "1380px";
$laptop: "1199px";
$tablet: "1024px";
$tablet-md: "992px";
$mobile-lg: "767px";
$mobile-md: "600px";
$mobile-sm: "560px";




// Focus
$focus-outline-color:rgba($white, 0);
$focus-outline-width: 0px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 0px;
$focus-outline:none;


//disply 


// Mixin CSS
$base-duration: 300ms;
$base-timing: ease;

//gredient

$list1:$blue 0%, $dark-blue 100%; 


@mixin gradient($direction, $list1) { 
  background: -webkit-linear-gradient($direction, $list1);
  background: -moz-linear-gradient($direction, $list1);
  background: -o-linear-gradient($direction, $list1);
  background: linear-gradient($direction, $list1);  
}



@mixin border-radius($top-left:10px, $top-right:null, $bottom-right:null, $bottom-left:null){
  -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
     -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
      -ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
          border-radius: $top-left $top-right $bottom-right $bottom-left;  
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}



// boxshadow
@mixin box-shadow($top, $left, $blur, $spread, $color) {
     -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
    -ms-box-shadow: $top $left $blur $spread $color;
}
//boxsizing
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
     -moz-box-sizing: $box-model; // Firefox <= 19
          box-sizing: $box-model;
}

// Rotate
@mixin rotate ($deg) { 
	@include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
    	-webkit-transform-origin: $origin;
    	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}






// keyframe animation
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

/********************************************************************
 * jQuery mmenu
 */
@import "../../../node_modules/jquery.mmenu/dist/jquery.mmenu";
@import "../../../node_modules/jquery.mmenu/dist/extensions/positioning/jquery.mmenu.positioning";


// Hide on start
#page > #mobileMenu {
    display: none;
}

.mobile-menu {
    .fa-bars {
        font-size: 1.5rem;
    }
}

// Customizations
.mm-menu {
    .mm-logo {
        text-align: center;
    }

    .mm-listitem_selected {
        color: $primary;
    }
}
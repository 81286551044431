/*************typography*************/
h1{
	  font-size:$font-size-h1;
	  font-weight:$font-weight-800;
	  font-family:$title-font;
	  color:$blue;
	  text-transform: uppercase;
	      line-height: 1.2em;
    letter-spacing: 0.02em;
   margin-bottom: 15px;
         @media(max-width:$mobile-lg){
	        font-size:17px;
	      }
}

h2{
	   font-size:$font-size-h2;
	     font-family:$title-font;
	  font-weight:$font-weight-800;
	  color:$dark-black;
	     line-height: 1.2em;
    letter-spacing: 0.02em;
    margin-bottom: 20px;
          @media(max-width:$mobile-lg){
	        font-size:14px;
	      }
}
h3{
     font-size:$font-size-h3;
     color:$dark-black;
     font-weight:$font-weight-600;
         line-height: 1.35em;
    letter-spacing: 0em;
    margin-bottom: 20px;
      @media(max-width:$mobile-lg){
	        font-size:14px;
	      }
}

h4{
     font-size:$font-size-h4;
 	  color:$blue;
     font-weight:$font-weight-600;
         line-height: 1.35em;
    letter-spacing: 0em;
    margin-bottom: 20px;
      @media(max-width:$mobile-lg){
	        font-size:14px;
	      }
}


p{
	  font-weight:$font-weight-normal;
	      line-height: 1.6em;
	      color:$para-text;
	      font-size: $font-16;
	      @media(max-width:$mobile-lg){
	        font-size:14px;
	      }
	      a{
	        display:inline;
	      }
}

a{text-decoration: none;
    color: rgb(14, 101, 181);
    display: inline-block;
    @include transition(all 0.3s ease);
    &:focus,
    &:hover{
	 text-decoration: underline;
	 outline:none;
}
}


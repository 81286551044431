
*, *::after, *::before {
	box-sizing: border-box;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

html {
	scroll-behavior: smooth;
	box-sizing: border-box;

	::-moz-selection {

		/* Code for Firefox */
		background: #AFDDFF;
		color: #000;
		text-shadow: none;
	}

	::selection {
		background: #AFDDFF;
		color: #000;
		text-shadow: none;
	}

}

img {
	max-width: 100%;
}

.leftimage{
	  float:left;
	  	 margin:0px 45px 45px 0px;
	  	 display:inline-block;
	  	 max-width:50%;
}
.rightimage{
	 float:right;
	 margin:0px 0 45px 45px;
	   	 display:inline-block;
max-width:50%;
}

.pd0 .column {
	padding: 0;
}

.pd20 {
	padding: 20px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}
.mb-50{
	 margin-bottom:50px;
}
hr{
	 border-top: 1px dotted rgb(102, 102, 102);
	 margin-top: 40px;
    margin-bottom: 40px;
        width: 100%;
    clear: both;
}
body {
	padding: 0;
	margin: 0;
	color: $para-text;
	font-size: $font-16;
	font-family: $base-font;
	line-height: 1.2em;
	font-weight: $font-weight-400;
	background-color: $grey;
	background-image: url(../assets/chriopractic_bischoff_hg.jpg);
	background-repeat: repeat;
}

/**********HEader css****/
header {
	background: $header-bg;

	.logo {
		img {
			margin: 0 auto;
		}

	}

}

/*********Banner css**********/
.banner-section {
	.row {
		border-bottom: 1px solid #fff;

		.chiropraktik-img {
		}

	}


	.carousel-control-prev, .carousel-control-next {
		width: 35px;
		color: $grey;
		background-color: rgba($light-black, 0.7);
		font-size: 32px;
		@include border-radius(100px);
		height: 35px;
		position: absolute;
		top: 50%;
		@include transform(translate(0, -50%));

		&:hover {
			opacity: 1;
		}

	}

	.carousel-control-prev {
		left: 10px;
	}

	.carousel-control-next {
		right: 10px;
	}

}

.carousel-fade .carousel-item {
	opacity: 0;
	transition-duration: .6s;
	transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
	opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
	opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
	transform: translateX(0);
	transform: translate3d(0, 0, 0);
}



/*************desktop menu *******/
.desktopmenu {
	h6 {
		color: $white;
		margin-left: -16px
	}

	.column {
		padding: 30px 20px 20px 58px;

		&.col1, &.col3 {
			background-color: $blue;
		}

		&.col2 {
			background-color: $dark-blue;
		}

		ul {
			color: $white;
			font-size: $font-14;
			line-height: 1.6em;
			padding: 0;
			margin: 0;

			li {
				position:relative;
				list-style: none;
				&:before{
					 content:"";
					 position: absolute;
					 left: -14px;
                     top: 8px;
					 width:4px;
					 height:4px;
					 background-color:$white;
					 @include border-radius(100%);
				}
				a {
					color: $light-pink;

					&:hover {
						color: $sky-blue;
						text-decoration:none;
					}

				}

			}

		}

	}

}

/************Footer**********/
footer {
	background-color: $grey;
	padding: 20px 0;
	font-size: $font-14;
	line-height: 1.3em;
	margin-bottom: 5px;

	p {
		margin-bottom: 5px;
		font-size:14px;
	}
	.content {
		p {
			strong{
			color:$light-black;
			}
		}
	}

	a { 
		color: $white;
		text-decoration: underline;
	}

	.times {
		margin-bottom: 15px;
		 color:$white;
		 font-size:14px;
		p{
		 color:$white;
		  font-size:14px;
		}
	}

	.address-time {
		border-right: 1px solid $white;
		color: $white;
		 font-size:14px;
		 	p{
		 color:$white;
		  font-size:14px;
		}
	}

	table {
		width: 100%;

		td {
			padding: 4px 3px 4px 0;
			vertical-align: top;
		}

		tr {
			td {
				&::first-child {
					width: 40%;
				}

			}

		}

	}

	.content {
		color: $light-black;
	}

}

/*************Page content***********/
.page-content {
	background-color: $light-pink;
	padding-top: 0px;
	padding-bottom: 0px;

	sidebar {
		background-color: $white;
		display: block;
		padding-bottom: 20px;

		.bluebox {
			padding: 20px;
			background-color: $light-sky-blue;
			p{
			  font-size:14px;
			  margin-bottom:20px;
			}
			.num-mail {
				letter-spacing: 0.1em;

				a {
					font-weight: $font-weight-600;
				}

			}

		}

		.links {
			a {
				font-size: $font-12;
				color: $para-text;
				display: inline-block;
				line-height: $font-12;

				&:hover {
					text-decoration: underline;
				}

			}

		}

	}
	aside{
	  ul{
       padding:0 0 0 40px;
       margin:20px 0;
	    li{
	    position:relative;
	    margin:5px 0;
	    }
	  }
	}

}
.team-pg,
.home{
	  h2{
	    color:$blue;
	  }
}
.team-pg{
  .team-members{
	  h4{
	     color:$para-text;
	  }
	}
}

.press-pics{
	img{
	 width:100%;
	}
}

#button {
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 42px;
    z-index:999;
      opacity: 0;
    visibility: hidden;
    &.show{
    opacity: 1;
    visibility: visible;
  }
}


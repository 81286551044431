@media(max-width:$laptop) {
	.container {
		max-width: 100%;
	}

	.mobile-menu {
		background-color: $blue;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;

		.module {
			display: block;
			margin: 0;
			padding: 0;
			list-style: none;
			text-align: right;

			svg {
				path {
					color: $white;
				}

			}

		}

	}

	.mm-menu_offcanvas {
		z-index: 9999;
        width:100%;
        min-width: 100%;
        max-width: 100%;
		&.mm-menu_position-right {
			left: 100%;
			@include transition(all 0.3s ease);
			display: block;
		}

		&.mm-menu_opened {
			&.mm-menu_position-right {
				left: 0;
			}

		}
		.mm-panel_has-navbar{
			    background: $blue;
			.mm-navbar{
				 background: $blue;
				 a{
				   color:$white;
				 }
			}
			.mm-listview{
				  li{
				  	  border:0;
				  	  a{
				  	  	  color:$white;
				  	  	  border:0;
				  	  	  &:hover{
				  	  	  	    color: $mob-menu-txt;
				  	  	  	    background-color: transparent;
				  	  	  }

				  	  }
				  	  &.mm-listitem_selected{
				  	  	  a{

				  	  	  	  font-weight: $font-weight-600;
				  	  	  	     color: $mob-menu-txt;
				  	  	  	       	    background-color: transparent;
				  	  	  }
				  	  }
				  	  &.mm-listitem{
                          &:after{
                          	  display:none;
                          }
				  	  }
				  }
			}
		
		}
		.mm-btn{
			&:before,
			&:after{
			
              border-color: $white;
         
			}
			&.mm-btn_close{
				     bottom: auto;
				     height: 44px;
				     text-align: center;
				     right:0;
				     &:after,
				     &:before{
                          width: 10px;
                           height: 10px;
				     }
				     	&:after{
				    right: 32px;
			}
			}
			&.mm-btn_prev{
				  left:0;
				  right:auto;
				  bottom:auto;
				  top:8px;

			}
		}


	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened~.mm-slideout {
		transform: translate3d(0vw, 0, 0);
	}

}

@media(max-width:$mobile-lg) {

  .leftimage,
  .rightimage{
    display:block;
    margin:10px auto;
    max-width:100%;
  }
	.page-content {
		display: flex;
		flex-direction: column-reverse;
		padding-bottom: 0;
	}

	sidebar {
		img {
			&.mb-15 {
				margin-bottom: 40px;
			}

			&.blueimg {
				margin-top: 20px;
				margin-bottom: 20px;
			}

		}

		.links {
			a {
				color: rgb(14, 101, 181);
			}

		}

	}

	footer {
		.times, .address {
			font-size: 13px;

			p {
				color: $white;
				font-size: 13px;
			}

		}

		.content {
			color: rgb(102, 102, 102);
			font-size: 13px;

			p {
				color: rgb(102, 102, 102);
				font-size: 13px;
			}

			.col-12 {
				margin-bottom: 40px;
			}

		}

	}

}